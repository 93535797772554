
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { ElForm } from 'element-ui/types/form'
import { drawPolygon } from '@/utils/formatData'

type Lnglat = {
  longitude: number;
  latitude: number;
}

@Component
export default class IrrigationArea extends Vue {
  private info = {
    projectAreaId: '',
    projectId: this.$route.query.projectId,
    soilType: '', // 土壤类型
    valveDevices: '', // 电磁阀列表
    soilDevices: '', // 土壤列表
    waterDevices: '', // 水电列表
    electricDevices: '' // 电表列表
  }

  private rules = {
    projectAreaId: [{ required: true, message: '请选择区域', trigger: ['change'] }]
  }

  private soilTypeList =[]

  private areaList = []
  private valveDevicesList = []
  private soilDevicesList = []
  private waterDevicesList = []
  private breakerDeviceList = []
  private areaName = ''
  private acreage = ''
  private map: AMap.Map | any = null
  private submitShow = false
  private ploygons: Array<AMap.Polygon> = []

  created () {
    this.getSoilTypeList()
    if (this.$route.params.id) {
      this.getArea()
    } else {
      this.loadData()
    }
    this.getAreaList()
    this.getAreaDevices()
  }

  // 获取土壤类型
  getSoilTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'soilType' }).then(res => {
      console.log('res: ', res)
      this.soilTypeList = res.soilType || []
    })
  }

  getAreaDevices () {
    const info = this.$route.params.id
      ? { projectAreaId: this.$route.params.id }
      : {}
    this.$axios.get(this.$apis.irrigation.selectUpdateAreaDeviceList, {
      ...info,
      projectId: this.$route.query.projectId
    }).then((res) => {
      this.valveDevicesList = res.valveDevice || []
      this.soilDevicesList = res.soilDevice || []
      this.waterDevicesList = res.waterDevice || []
      this.breakerDeviceList = res.breakerDevice || []
    })
  }

  //  区域详情
  getArea () {
    this.$axios.get(this.$apis.irrigation.irrigationAreaDetail, {
      projectAreaId: this.$route.params.id
    }).then((res) => {
      this.info = {
        projectAreaId: res.projectArea.projectAreaId,
        projectId: res.projectArea.projectId,
        valveDevices: res.valveList || [], // 电磁阀列表
        soilDevices: res.soilList || [], // 土壤列表
        waterDevices: res.waterList || [], // 水电列表
        electricDevices: res.electricList || [], // 电表列表
        soilType: res.projectArea.soilType
      }
      this.areaName = res.projectArea.areaName
      this.acreage = res.projectArea.areaAcreage
      this.loadData()
    })
  }

  // 区域列表
  getAreaList () {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.$route.query.projectId,
      isIdimArea: '0',
      notAllArea: '1'
    }).then((res) => {
      this.areaList = res.list || []
    })
  }

  // 获取电子围栏
  loadData () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectAreaId: this.info.projectAreaId,
      projectId: this.$route.query.projectId
    }).then((res) => {
      !this.map && this.loadMap(res.projectInfo, res.projectLocation, res.areaLocation || null)
      this.map && res.areaLocation && this.addAreaPolygon(res.areaLocation)
    })
  }

  // 地图初始化
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>, listArea: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('container', {
        center: center,
        zoom: 11
      })
      this.map = map
      drawPolygon(this.map, list, null, null)
      if (listArea) {
        this.addAreaPolygon(listArea)
      }
    })
  }

  // 画区域电子围栏
  addAreaPolygon (listArea: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    if (this.ploygons) {
      this.map.remove(this.ploygons)
    }
    const polygonProjectAreaColor = {
      fillOpacity: 0.3, // 背景透明
      strokeWeight: 4, // 线条宽度
      fillColor: '#ff5555', // 背景色
      strokeColor: '#ff5555' // 线条色
    }
    this.ploygons = drawPolygon(this.map, listArea, polygonProjectAreaColor, null)
  }

  areaChange (id: string) {
    const area: any = this.areaList.find((item: {projectAreaId: string}) => {
      return item.projectAreaId === id
    })
    this.acreage = area.areaAcreage
    this.loadData()
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.irrigation.irrigationAreaAdd, {
          ...this.info,
          isUpdate: this.$route.params.id ? '1' : '0'
        }).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$router.push({
            path: '/irrigationmodelsetting/detail/setting',
            query: {
              projectId: this.$route.query.projectId
            }
          })
        })
          .catch((err) => {
            this.submitShow = false
            this.$message(err)
          })
      }
    })
  }
}
